import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Profile } from "../db/types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const titleCase = (str: string) => {
  return str
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const toSentenceCase = (str: string) => {
  return str
    .split("_")
    .map((word) => {
      if (
        word === "cat" ||
        word === "ipr" ||
        word == "soa" ||
        word == "fsg" ||
        word === "aml" ||
        word === "crm" ||
        word === "rpq" ||
        word === "ina" ||
        word === "ctf" ||
        word === "fna"
      ) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(" ");
};

export const canAccessAI = (user: Profile) => {
  switch (true) {
    case user.email == "jack.standing@wtfglimited.com":
      return true;
    case user.email == "frank.paul@wtfglimited.com":
      return true;
    case user.email == "connor+adviser@knkt.com.au":
      return false;
    case user.email == "max.song@wtfglimited.com":
      return true;
    case user.email == "samuel.chen@wtfglimited.com":
      return true;
    case user.email.split("@").pop() == "knkt.com.au":
      return true;
    default:
      return false;
  }
};

//Handle types of error messages: from https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
}

export * from "./dates";
